/*----------------------------------------------------------------------------*\
    Mosaic
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.mosaic{
    .mosaic__block{
        width: 100%;
        padding-bottom: 100%;
        position: relative;
    }

    .mosaic__content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        text-align: center;
        overflow: hidden;

        h3{
            line-height: 1.2;
            overflow: hidden;
        }
    }

    .mosaic__content--island{
        position: absolute;
        top:0;
        left: 0;
        padding: $space*2;
    }

    .mosaic__content--image{
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translate(0, 0);
        -webkit-transform: translate(0, 0);

        img{
            min-width: 100%;
        }
    }

    .mosaic__title{
        font-family: $face-base;
        font-weight: 800;
        letter-spacing: 3px;
        @include font-size($base-font-size);
    }
}


@include media-query(lt-desk){
    .mosaic--grow{
        .mosaic__block{
            padding-bottom: 0;
        }
        .mosaic__content{
            position: relative;
            text-align: center;
            overflow: hidden;

            h3{
                line-height: 1.2;
                overflow: hidden;
            }
        }

        .mosaic__content--island{
            position: relative;
        }
    }

    .mosaic{
        .mosaic__block{
            //padding-bottom: 0;
        }
        .mosaic__content{
            //position: relative;
            //text-align: center;
            //overflow: hidden;
            font-size: $milli;
            h3{
                line-height: 1.2;
                overflow: hidden;
                font-size: $beta;
            }

            p{
                line-height: 1.2;
            }
        }

        .mosaic__content--island{
            //position: relative;
        }
    }
}