/*----------------------------------------------------------------------------*\
    Button
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.button{
    border-radius: $space-half/2;
    text-transform: uppercase;
    font-family: $face-base;
    font-weight: 800;
    font-size: $milli;
    letter-spacing: 2px;
}

.button--medium{
    padding: $space-half/2 $space;
}

.button--large{
    font-size: $base-font-size;
    padding: 0 $space;
    letter-spacing: 2px;
}

.button--brand{
    background: color(brand);
    color: color(blank);
    border: 1px solid color(brand);

    &:hover{
        background: tint(color(brand), 10%);
    }
}

.button--squared{
    border-radius: 0;
    display: block;
}

.button--ghost{
    color: color(base-light);

    &:hover{
        background: rgba(color(base), 0.1);
    }
}

.dealer--button{
    position: relative;
    padding: $space-half;

    .button{
        //height: $space*2;
        //line-height: $space*2;
        //vertical-align: middle;
        //padding: 0 $space;
        //position: relative;
        //z-index: 100;
    }
}

@include media-query(desk){
    .dealer--button{
        position: absolute;
        right: $space;
        top: calc(100% + #{$space});
        padding: 0;

        .button{
            height: $space*2;
            line-height: $space*2;
            vertical-align: middle;
            padding: 0 $space;
            position: relative;
            z-index: 100;
        }
    }
}