/*----------------------------------------------------------------------------*\
    $TOPLEVEL
    html, body, .wrapper
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/
    html{
        -webkit-font-smoothing: antialiased;
    }
    /* commented backslash hack \*/
    html, body { height: 100%; background: color(base); }
    /* end hack */
    * html .wrapper { height: 100%; }

    body{
        position: relative;
        min-height: 100%;
        color: color(blank);

    }


    /**
     * Make wrapper 100% height
     */
    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
        z-index: 3;
    }

        .site-width{
            margin: 0 auto;
            position: relative;
            @include rem(max-width, $site-width);
            @include rem(padding, 0 $space);
        }
