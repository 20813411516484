/*----------------------------------------------------------------------------*\
    Category navigation
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
.nav--category{
    display: inline-block;
    white-space: nowrap;
    border: 1px solid color(base-light);
    font-size: 0;

    a{
        padding: $space-half/1.5 $space;
        text-decoration: none;
        color: color(blank);
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        @include font-size($milli, 1);
        border-right: 1px solid color(base-light);
        transition: background 0.1s $transition;
        -webkit-transition: background 0.1s $transition;

        &:hover{
            background: color(base-med);
        }

        &.active{
            background: color(blank);
            color: color(brand-light);
        }
    }

    li:last-child{
        a{
            border-right: 0;
        }
    }
}

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

    /**
     * Palm state
     */
    @include media-query(lt-desk){
        .nav--category{
            display: block;
            border: 0;
            background: rgba(color(subtle), 0.3);
            padding: $space-half 0;

            li{
                display: block;

                a{
                    display: block;
                    border: 0;
                    color: color(base-med);

                    &:hover{
                        background: color(blank);
                    }
                }
            }
        }
    }
