/* Slider */

.slider-desktop {
    display: block;
    
    @include media-query(palm) {
        display: none !important;
    }
}

.slider-mobile {
    display: block;
    height: 300px !important;
    
    @include media-query(gt-palm) {
        display: none !important;
    }
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: auto;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


/* Slider */

.slick-slider{
    position: relative;
}

.slick-list {
    .slick-loading & {
        background: #fff image-url("ajax-loader.gif") center center no-repeat;
    }
}

.slideshow img {
      display: none;
    }
    .slideshow img:first-child {
      display: block;
    }
    .slideshow .slick-initialized img {
      display: block;
    }


/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: $space*3;
    width: $space*1.5;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: color(base-light);
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 10;
    transition: background 0.2s $transition;

    &:hover, &:focus {
        outline: none;

        &:before {
        }
    }
    &:hover{
        background: color(subtle);
    }
    &.slick-disabled:before {
        opacity: 0.1;
    }
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        width: $space-half;
        background: color(base);
    }


}

.slick-prev {
    left: 0;
    border-radius: 0 $space*3 $space*3 0;
    box-shadow: 4px 0px 4px -1px rgba(color(base), 0.3);
    &:before{
        -webkit-transform: rotate(-45deg) translate(5px, 0);
            -ms-transform: rotate(-45deg) translate(5px, 0);
            transform: rotate(-45deg) translate(5px, 0);
            left: 0;
    }
    &:after{
        -webkit-transform: rotate(45deg) translate(5px, 0);
            -ms-transform: rotate(45deg) translate(5px, 0);
            transform: rotate(45deg) translate(5px, 0);
            left: 0;
    }
}

.slick-next {
    right: 0;
    border-radius: $space*3 0 0 $space*3;
    box-shadow: -4px 0px 4px -1px rgba(color(base), 0.3);
    &:before{
        -webkit-transform: rotate(45deg) translate(-5px, 0);
            -ms-transform: rotate(45deg) translate(-5px, 0);
            transform: rotate(45deg) translate(-5px, 0);
            right: 0;
    }
    &:after{
        -webkit-transform: rotate(-45deg) translate(-5px, 0);
            -ms-transform: rotate(-45deg) translate(-5px, 0);
            transform: rotate(-45deg) translate(-5px, 0);
            right: 0;
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: $space*2;
}

.slick-dots {
    position: absolute;
    bottom: -$space;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: $space-half/3;
        width: $space-half/3;
        margin: 0 $space-half;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: color(base-light);
            border-radius: 50%;
            display: block;
            height: $space-half/3;
            width: $space-half/3;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: $space-half/3;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                background: rgba(color(subtle), 1);
                &:before {
                    opacity: 1;
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: $space;
                height: $space;
                text-align: center;
            }
        }
        &.slick-active button {
            background: rgba(color(brand-light), 1);
            //border: 2px solid rgba(color(brand-light), 1);
        }
    }
}
