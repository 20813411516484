/*----------------------------------------------------------------------------*\
    News
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.news{
    .news__title{
        font-family: $face-base;
        font-weight: 800;
        color: color(brand-light);
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: $milli;
    }

    hr{
        color:color(base-light);
        background: color(base-light);
        border: 0;
        display: block;
        height:1px;
    }
}