/*----------------------------------------------------------------------------*\
    Category navigation
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
.nav--footer{
    display: inline-block;
    white-space: normal;
    font-size: 0;
    margin-right: -$space-half;
    border: 1px solid color(base-light);

    a{
        padding: $space-half/2 $space-half;
        text-decoration: none;
        color: color(base-light);
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        @include font-size($milli, 1);
        transition: color 0.1s $transition;
        -webkit-transition: color 0.1s $transition;

        &:hover{
            color: color(subtle);
        }

        &.active{
            background: color(blank);
            color: color(brand-light);
        }
    }

    li:last-child{
        a{
            border-right: 0;
        }
    }
}

@include media-query(palm){
    .nav--footer{
        display: block;
    }
}
