/*----------------------------------------------------------------------------*\
    $HEADER
    top of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .header{
        background: color(base-dark);

        .logo{
            display: inline-block;
            margin-right: $space-half;
            vertical-align: middle;
        }

        .menu--container{
            display: inline-block;
            vertical-align: middle;
           // position: relative;
        }

        .icon--menu{
            border: 1px solid color(base-med);
            display: inline-block;
            vertical-align: middle;
            float: right;
            padding: $space-half;

            a{
                display: block;
            }

            .hamburger{
                height: $space;
                width: $space;
                display: block;

                line {
                    transition: transform 0.5s $transition;
                    stroke: color(blank);
                }

                .one{
                    transform: rotate(0deg);
                }
                .two{
                    transition: opacity 0.2s ease;
                }
                .three{
                    transform: rotate(0deg);
                }
            }

            &[data-state="active"]{
                .hamburger{
                    .one{
                        transform: translate(8.8px, 0.5px) rotate(45deg);
                    }
                    .two{
                        opacity: 0;
                    }
                    .three{
                        transform: translate(-11px, 9px) rotate(-45deg);
                    }
                }
            }
        }
    }



/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(lt-desk){
            .header{
                background: color(base-dark);
                position: relative;

                .menu--container{
                    display: block;
                    position: absolute;
                    background: color(blank);
                    top:100%;
                    left:0;
                    z-index: 100;
                    transition: left 0.5s $transition;
                    -webkit-transition: left 0.5s $transition;
                    -webkit-transform: translatez(0);
                    -moz-transform: translatez(0);
                    -ms-transform: translatez(0);
                    -o-transform: translatez(0);
                    transform: translatez(0);

                    &[data-state="passive"]{
                        left: -100%;

                    }
                }
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
