/*----------------------------------------------------------------------------*\
    $FONT
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

@font-face {
    font-family: 'expressway';
    src: url('../fonts/expressway_xb-webfont.eot');
    src: url('../fonts/expressway_xb-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/expressway_xb-webfont.woff2') format('woff2'),
         url('../fonts/expressway_xb-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'expressway';
    src: url('../fonts/expressway_rg-webfont.eot');
    src: url('../fonts/expressway_rg-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/expressway_rg-webfont.woff2') format('woff2'),
         url('../fonts/expressway_rg-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'aleolight';
    src: url('../fonts/Aleo-Light-webfont.eot');
    src: url('../fonts/Aleo-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Aleo-Light-webfont.woff') format('woff'),
         url('../fonts/Aleo-Light-webfont.ttf') format('truetype'),
         url('../fonts/Aleo-Light-webfont.svg#aleolight') format('svg');
    font-weight: normal;
    font-style: normal;

}


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
