/*----------------------------------------------------------------------------*\
    Breadcrumbs
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .breadcrumbs{
        ol{
            line-height: 1em;
            padding: $space-half 0;

            li{

                line-height: 1em;

                &:before{
                    line-height: 1em;
                    color: shade(color(subtle), 30%);
                    @include font-size($micro);
                    padding: 0 $space-half/2;
                }
            }

            a{
                line-height: 1em;
                color: shade(color(subtle), 30%);
                text-decoration: none;
                @include font-size($milli);

                &:hover{
                    color: color(subtle);
                }
            }
        }
    }
