/*----------------------------------------------------------------------------*\
    $BLOCK
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .block{}

    .customer-block{
        height: 204px;
    }

    .block--container{
        border: 1px solid color(base-med);

        img{
            display: block;
            max-width: 100%;
            min-width: 100%;
        }

        .block--link{
            display: block;
            text-align: center;
            text-decoration: none;
            background: color(base);
            transition: background 0.2s $transition;

            .block--title{
                font-family: $face-base;
                font-weight: 800;
                letter-spacing: 2px;
                @include font-size($milli, 1.5);
                text-transform: uppercase;
                color: color(blank);
                transition: color 0.2s $transition;

            }

            &:hover{
                background: color(blank);

                .block--title{
                    color: color(brand);
                }
            }
        }
    }

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
