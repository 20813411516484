/*----------------------------------------------------------------------------*\
    typography
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    font-family: $face-brand;
}

a{
    color: color(brand-light);
}

.title--icon{


    svg{
        vertical-align: middle;
        margin-right: $space-half;
    }

    a{
        text-decoration: none;

        &:hover{
            text-decoration: underline;
        }
    }
}

.face-brand{
    font-family: $face-brand;
}

.subtitle{
    position: relative;
    z-index: 1;

    &:before{
        border-top: 1px solid color(base-med);
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%; left: 0; right: 0; bottom: 0;
        z-index: -1;
    }

    span{
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 0 $space-half;
        background: color(base);
    }
}

.heading-sec{
    font-family: $face-base !important;
    font-weight: 800;
    letter-spacing: 2px;
    text-transform: uppercase;
}