/*----------------------------------------------------------------------------*\
    $LAYER
    page part
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .layer{
        padding: $space 0;
    }

    .layer--wide{
        padding: $space*2 0;
    }

    .bg--project{
        background: url('/public/gfx/bg--project.jpg') top center no-repeat;
    }

    .hero{
        background-position: top center;
        background-repeat: no-repeat;
        padding: $space*3 0;

        .title--hero{
            color: color(blank);
            font-family: $face-base;
            text-transform: uppercase;
            font-size: $mega;
            font-weight: 800;
            letter-spacing: $space-half;
        }
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(lt-desk){
            .layer{
                padding: $space-half 0;
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
