/*----------------------------------------------------------------------------*\
    $FOOTER
    bottom of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .footer{

        background: color(base-dark);
    }

    .socmed{
        ul{
            list-style-type: none;
            display: inline-block;
            margin: 0;
            padding: 0;
        }
        li{
            display: inline-block;
        }
        a{
            display: inline-block;
            height: $space;
            width: $space;
            background: color(blank);
            border-radius: 50%;
            position: relative;
            transition: background 0.1s $transition;
            -webkit-transition: background 0.1s $transition;
            margin-left: $space-half/2;
            text-align: center;

            &:before,
            &:after{
                content: '';
                display: block;
                position: absolute;
                height: 4px;
                width: 4px;
                background: color(base-dark);
                border-radius: 50%;
                top: 50%;
                margin-top: -2px;
            }

            &:before{
                left: -2px;
            }
            &:after{
                right: -2px;
            }
            &:hover{
                background: color(brand-light);
            }
        }
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
