/*----------------------------------------------------------------------------*\
    Dealers
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
.dealer--wrapper{
    height: 132px;
}
.dealer__subtitle{
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.hint{
    color: color(subtle);
    font-size: $milli;
    position: relative;
    min-height: $space;
    margin-bottom: $space-half;

    .hint__text{
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.5s $transition;

        &[data-state=active]{
            opacity: 1;
        }
    }
}

.dealer-select{
    display: inline-block;
    font-size: 0;

    select{
        display: inline-block;
        -webkit-appearance: none;  /*Removes default chrome and safari style*/
		-moz-appearance: none; /* Removes Default Firefox style*/
        appearance:none;
        border-radius: 0;
        background: url('/public/gfx/arrow.png') no-repeat 95% center;
        border: 1px solid color(base-light);
        font-size: $milli;
        padding: $space-half $space*1.2 $space-half $space-half;
        color: color(subtle);
    }

    option{
        color: color(base);
        background: color(subtle);
    }

    input{
        font-size: $base-font-size;
    }

    input[type=text]{
        border: 1px solid color(blank);
        font-size: $base-font-size;
        padding: $space-half;
        color: color(base);
        display: inline-block;
        border-left: 0;
        border-right: 0;
        padding-left: 0;
        padding-right: 0;
        width: 0;
        transition: all 0.5s $transition;

        &[data-state=active]{
            width: $space*10;
            border-left: 1px solid color(blank);
            border-right: 1px solid color(blank);
            padding-left: $space-half;
            padding-right: $space-half;
        }
    }
    input[type=submit]{
        background: color(brand-light) url('/public/gfx/search.png') no-repeat center center;
        border: 1px solid color(brand-light);
        padding: $space-half;
        outline: 0;
        display: inline-block;
        width: 0;
        border-left: 0;
        border-right: 0;
        padding-left: 0;
        padding-right: 0;
        transition: all 0.5s $transition;

        &[data-state=active]{
            width: $space*2;
            border-left: 1px solid color(brand-light);
            border-right: 1px solid color(brand-light);
            padding-left: $space-half;
            padding-right: $space-half;
        }
    }
}

#maps{
    height: $space*22;
}
.js--premium-silver{
    color: #ABABAB;
}
.js--premium-gold{
    color: #D4AF37;
}
.marker-legend{
    .marker-legend__title{
        padding-top: $space/2;
        font-family: $face-brand;
    }
    img{
        display: block;
        height: $space*1.5;
    }
}
