/*----------------------------------------------------------------------------*\
    $COLOR
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    /* Color function
       Using colors in css example:
       background-color: color(brand);
    \*------------------------------------------------------------------------*/
    @function color($key) {
        @if map-has-key($colors, $key) {
            @return map-get($colors, $key);
        }

        @error "Unknown `#{$key}` in $colors.";
        @return null;
    }


    /* Class definition
       Using colors in HTML example:
       <div class='bg--brand'> </div>
    \*------------------------------------------------------------------------*/
    @each $color, $value in $colors {
        .bg--#{$color} {
            background-color: $value;
        }
        .color--#{$color} {
            color: $value !important;
        }
    }

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
