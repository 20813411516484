/*----------------------------------------------------------------------------*\
    Category navigation
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
.nav--extra{
    display: inline-block;
    white-space: normal;
    font-size: 0;
    margin-right: -$space-half;

    a{
        padding: $space-half/2 $space-half;
        text-decoration: none;
        color: color(blank);
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 3px;
        @include font-size($milli, 1);
        transition: background 0.1s $transition;
        -webkit-transition: background 0.1s $transition;

        &:hover{
            background: color(base-med);
        }

        &.active{
            background: color(blank);
            color: color(brand-light);
        }
    }

    li:last-child{
        a{
            border-right: 0;
        }
    }
}


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

    /**
     * Palm state
     */
    @include media-query(lt-desk){
        .nav--extra{
            display: block;
            border: 0;
            padding: $space-half 0;

            li{
                display: block;

                a{
                    display: block;
                    border: 0;
                    color: color(base-med);
                    padding: $space-half $space;

                    &:hover{
                        background: color(blank);
                    }
                }
            }
        }
    }
