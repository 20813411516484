/*----------------------------------------------------------------------------*\
    Hero
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.hero__caption--home{
    background: rgba(color(brand-light), 0.7);
    color: #fff;
    text-align: center;
    padding: $space $space*2;

    position: relative;
    font-family: $face-brand;
    font-size: $alpha;


    .hero__title{
        font-size: $beta;
        font-weight: 800;
        font-family: $face-base;
        text-transform: uppercase;
        letter-spacing: $space-half;
    }
}

@include media-query(desk){

    .hero--home{
        position: relative;
        min-height: $space*20;
    }

    .hero__caption--home{

        margin-top: -$space*4;
        .hero__title{
            font-size: $mega;
        }
    }


}

